import Separator from "@/shared/Separator";
import { ParentGrid, Card } from "./style";
import { GridStyleColor } from "@/widgets/Container/Templates/stylesGridGeneric.js";
import Banner from "@/widgets/Banner/Templates/Banner";
import { BannerCajaGris } from "@/shared/ContentBanner";
import BannerLazy from "@/widgets/Banner/Templates/BannerLazy";
import { sizeBanner } from "@/shared/DFP/Home/mapping";
import { useContainerWidget } from "hooks";
import Skeleton from "./Skeleton";

const filterSizeBanner = (slot, sizeBanner) => {
  return sizeBanner[Object.keys(sizeBanner).find((item) => item == slot)];
};

const Container3PisosB = (props) => {
  const { banners, link, linkImage, sectionClass = "", title } = props;

  const { indexNews, loading, titlesExtras, marfeelTitle } = useContainerWidget(
    { arrayLength: 6, containerName: "Container3PisosB", ...props }
  );

  const defaultTemplate = [
    "TwoxTwoVisual",
    "OnexOne",
    "OnexOne",
    "OnexOne",
    "OnexOne",
    "OnexOne",
    "OnexOne",
  ];

  console.log("templateNews", props.positionProperties?.[0]?.templateNews);
  return (
    props.content?.length > 0 && (
      <>
        {title && (
          <Separator
            title={title}
            link={link}
            sectionClass={sectionClass}
            extras={titlesExtras}
            linkImage={linkImage}
          />
        )}

        {loading && !props.isPreview ? (
          <Skeleton />
        ) : (
          <GridStyleColor>
            <ParentGrid
              data-mrf-recirculation={`${marfeelTitle}`}
              className={`Container3PisosB module-grid ${sectionClass}`}
            >
              {indexNews.map((item, index) => {
                if (index === 2) {
                  return (
                    <>
                      {banners && (
                        <div className="banner-container">
                          <BannerCajaGris
                            heightBanner="600px"
                            className="banner sticky"
                          >
                            {filterSizeBanner(banners, sizeBanner) ? (
                              <BannerLazy
                                isPreview={props.isPreview}
                                slotId={banners}
                                adsPath="home"
                                mapping={filterSizeBanner(banners, sizeBanner)}
                              />
                            ) : (
                              <Banner
                                isPreview={props.isPreview}
                                slotId={banners}
                              />
                            )}
                          </BannerCajaGris>
                        </div>
                      )}

                      <Card
                        className={`div${index + 1}`}
                        key={`container${index + 1}`}
                      >
                        {props.renderContainerWidgetCallback(
                          item + 1,
                          props.positionProperties?.[index]?.templateNews ??
                            defaultTemplate[index],
                          { containerName: "contenedor_3_pisos_B" }
                        )}
                      </Card>
                    </>
                  );
                }
                return (
                  <>
                    <Card
                      className={`div${index + 1}`}
                      key={`container${index + 1}`}
                    >
                      {props.renderContainerWidgetCallback(
                        item + 1,
                        props.positionProperties?.[index]?.templateNews ??
                          defaultTemplate[index],
                        { containerName: "contenedor_3_pisos_B" }
                      )}
                    </Card>
                  </>
                );
              })}
            </ParentGrid>
          </GridStyleColor>
        )}
      </>
    )
  );
};
export default Container3PisosB;
